<template>
  <main id="aboutusMain">
    <div class="container-fluid">
      <b-jumbotron id="jumbotron" border-variant="dark" header-level="5">
        <template #header>{{$t(`message.waw`)}}</template>
        <template #lead>
          {{$t(`message.wawmy`)}} 
        </template>
      </b-jumbotron>
    </div>

    <div class="container-fluid">
        <b-row class="pt-2 justify-content-center">
            <b-col cols="12">
                <h2 class="text-center">{{$t(`message.ourcom`)}}</h2>
                <p>{{$t(`message.wawmyact`)}}
                 <span class="d-block">{{$t(`message.createpr`)}}</span>
                 <span class="d-block">{{$t(`message.underwritingh`)}}</span>
                </p>
            </b-col>
            <b-col cols="12" class="pt-2">
                <h2 class="text-center">{{$t(`message.trdistchannel`)}}</h2>
                <p class="lead text-center">{{$t(`message.trdistthrough`)}}</p>
                <b-list-group class="text-center listb2b">
                  <b-list-group-item class="font-600">B2B Platform</b-list-group-item>
                  <b-list-group-item class="font-600">API</b-list-group-item>
                  <b-list-group-item class="font-600">Direct</b-list-group-item>
                </b-list-group>
            </b-col>
        </b-row>
        <b-row class="pt-2">
            <b-col cols="12">
                <h2 class="text-center">{{$t(`message.wawmyactot`)}}</h2>
            </b-col>            
        </b-row>
        <card-advance-profile />
        <b-row class="pt-2">
            <b-col cols="12">
              <h2 class="text-center">{{$t(`message.insoptmark`)}}</h2>
              <p class="lead text-center">{{$t(`message.revolutionizing`)}}</p>
              <p>{{$t(`message.p2pmark`)}}</p>
              <p></p>
            </b-col>
        </b-row>
    </div> 
  </main>
</template>

<script>
import {
  BRow, BCol, BCard, BImg, BCardTitle, BCardSubTitle, BCardBody, BCardImg, BCardText, BJumbotron, BListGroup, BListGroupItem
} from 'bootstrap-vue'
import CardAdvanceProfile from '../card/card-advance/CardAdvanceProfile.vue'
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BImg,
    BCardTitle,
    BCardSubTitle,
    BCardBody,
    BCardImg,
    BCardText,
    CardAdvanceProfile,
    BJumbotron,
    BListGroup, 
    BListGroupItem
  },
  
  data() {
    return {
    }
  },
}
</script>
<style>
  .listb2b > .list-group-item{
    color: var(--foreground-color);
  }
</style>