<template>
  <b-row>
    <b-col  
      xl="4"
      lg="6"
      md="6"
      sm="12">
      <b-card
        :img-src="require('@/assets/images/banner/banner-sft.png')"
        img-alt="Profile Cover Photo"
        img-top
        class="card-profile"
        >
        <div class="profile-image-wrapper">
          <div class="profile-image p-0">
            <b-avatar
              size="114"
              variant="light"
              :src="require('@/assets/images/quotation/vasilis.jpeg')"
            />
          </div>
        </div>
        <h3>V. Pallidis</h3>
        <h6 class="text-muted">
          Co-Founder - CEO
        </h6>
        <b-badge
          class="profile-badge"
          variant="light-primary"
        >
          Pro Level
        </b-badge>
        <hr class="mb-2">

        <!-- follower projects rank -->
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <h6 class="text-muted font-weight-bolder">
              Linkedin
            </h6>
            <h6 class="mb-0">
              <a href="https://www.linkedin.com/in/vassilis-pallidis/" target="_blank">Link</a>
            </h6>
          </div>
          <div>
            <h6 class="text-muted font-weight-bolder">
              Facebook
            </h6>
            <h3 class="mb-0">
              -
            </h3>
          </div>
          <div>
            <h6 class="text-muted font-weight-bolder">
              Instagram
            </h6>
            <h3 class="mb-0">
              -
            </h3>
          </div>
        </div>
        <!--/ follower projects rank -->
      </b-card>
    </b-col>

    <b-col  
      xl="4"
      lg="6"
      md="6"
      sm="12">
      <b-card
        :img-src="require('@/assets/images/banner/banner-sft.png')"
        img-alt="Profile Cover Photo"
        img-top
        class="card-profile"
        >
        <div class="profile-image-wrapper">
          <div class="profile-image p-0">
            <b-avatar
              size="114"
              variant="light"
              :src="require('@/assets/images/quotation/tasos.jpeg')"
            />
          </div>
        </div>
        <h3>T. Deriziotis</h3>
        <h6 class="text-muted">
          Co-Founder - CEO
        </h6>
        <b-badge
          class="profile-badge"
          variant="light-primary"
        >
          Pro Level
        </b-badge>
        <hr class="mb-2">

        <!-- follower projects rank -->
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <h6 class="text-muted font-weight-bolder">
              Linkedin
            </h6>
            <h6 class="mb-0">
              <a href="https://www.linkedin.com/in/tasos-derisiotis-443818a4/" target="_blank">Link</a>
            </h6>
          </div>
          <div>
            <h6 class="text-muted font-weight-bolder">
              Facebook
            </h6>
            <h3 class="mb-0">
              -
            </h3>
          </div>
          <div>
            <h6 class="text-muted font-weight-bolder">
              Instagram
            </h6>
            <h3 class="mb-0">
              -
            </h3>
          </div>
        </div>
        <!--/ follower projects rank -->
      </b-card>
    </b-col>

    <b-col  
      xl="4"
      lg="6"
      md="6"
      sm="12">
      <b-card
        :img-src="require('@/assets/images/banner/banner-sft.png')"
        img-alt="Profile Cover Photo"
        img-top
        class="card-profile"
        >
        <div class="profile-image-wrapper">
          <div class="profile-image p-0">
            <b-avatar
              size="114"
              variant="light"
              :src="require('@/assets/images/quotation/elena.jpeg')"
            />
          </div>
        </div>
        <h3>E. Derizioti</h3>
        <h6 class="text-muted">
          Sales Manager
        </h6>
        <b-badge
          class="profile-badge"
          variant="light-primary"
        >
          Pro Level
        </b-badge>
        <hr class="mb-2">

        <!-- follower projects rank -->
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <h6 class="text-muted font-weight-bolder">
              Linkedin
            </h6>
            <h6 class="mb-0">
              <a href="https://www.linkedin.com/company/safe-travel/" target="_blank">Link</a>
            </h6>
          </div>
          <div>
            <h6 class="text-muted font-weight-bolder">
              Facebook
            </h6>
            <h3 class="mb-0">
              -
            </h3>
          </div>
          <div>
            <h6 class="text-muted font-weight-bolder">
              Instagram
            </h6>
            <h3 class="mb-0">
              -
            </h3>
          </div>
        </div>
        <!--/ follower projects rank -->
      </b-card>
    </b-col>

    <b-col  
      xl="4"
      lg="6"
      md="6"
      sm="12">
      <b-card
        :img-src="require('@/assets/images/banner/banner-sft.png')"
        img-alt="Profile Cover Photo"
        img-top
        class="card-profile"
        >
        <div class="profile-image-wrapper">
          <div class="profile-image p-0">
            <b-avatar
              size="114"
              variant="light"
              :src="require('@/assets/images/quotation/panagiotis.jpeg')"
            />
          </div>
        </div>
        <h3>P. Deriziotis</h3>
        <h6 class="text-muted">
          Compliance & Legal Manager
        </h6>
        <b-badge
          class="profile-badge"
          variant="light-primary"
        >
          Pro Level
        </b-badge>
        <hr class="mb-2">

        <!-- follower projects rank -->
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <h6 class="text-muted font-weight-bolder">
              Linkedin
            </h6>
            <h6 class="mb-0">
              <a href="https://www.linkedin.com/in/panayotis-derisiotis-62a8748a/" target="_blank">Link</a>
            </h6>
          </div>
          <div>
            <h6 class="text-muted font-weight-bolder">
              Facebook
            </h6>
            <h3 class="mb-0">
              -
            </h3>
          </div>
          <div>
            <h6 class="text-muted font-weight-bolder">
              Instagram
            </h6>
            <h3 class="mb-0">
              -
            </h3>
          </div>
        </div>
        <!--/ follower projects rank -->
      </b-card>
    </b-col>

    <b-col  
      xl="4"
      lg="6"
      md="6"
      sm="12">
      <b-card
        :img-src="require('@/assets/images/banner/banner-sft.png')"
        img-alt="Profile Cover Photo"
        img-top
        class="card-profile"
        >
        <div class="profile-image-wrapper">
          <div class="profile-image p-0">
            <b-avatar
              size="114"
              variant="light"
              :src="require('@/assets/images/quotation/petro.jpeg')"
            />
          </div>
        </div>
        <h3>G. Petropoulos</h3>
        <h6 class="text-muted">
          Accountant
        </h6>
        <b-badge
          class="profile-badge"
          variant="light-primary"
        >
          Pro Level
        </b-badge>
        <hr class="mb-2">

        <!-- follower projects rank -->
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <h6 class="text-muted font-weight-bolder">
              Linkedin
            </h6>
            <h6 class="mb-0">
              <a href="https://www.linkedin.com/company/safe-travel/" target="_blank">Link</a>
            </h6>
          </div>
          <div>
            <h6 class="text-muted font-weight-bolder">
              Facebook
            </h6>
            <h3 class="mb-0">
              -
            </h3>
          </div>
          <div>
            <h6 class="text-muted font-weight-bolder">
              Instagram
            </h6>
            <h3 class="mb-0">
              -
            </h3>
          </div>
        </div>
        <!--/ follower projects rank -->
      </b-card>
    </b-col>
    <b-col  
      xl="4"
      lg="6"
      md="6"
      sm="12">
      <b-card
        :img-src="require('@/assets/images/banner/banner-sft.png')"
        img-alt="Profile Cover Photo"
        img-top
        class="card-profile"
        >
        <div class="profile-image-wrapper">
          <div class="profile-image p-0">
            <b-avatar
              size="114"
              variant="light"
              :src="require('@/assets/images/quotation/panos.jpeg')"
            />
          </div>
        </div>
        <h3>P. Paoulinis</h3>
        <h6 class="text-muted">
          Full-stack engineer
        </h6>
        <b-badge
          class="profile-badge"
          variant="light-primary"
        >
          Pro Level
        </b-badge>
        <hr class="mb-2">

        <!-- follower projects rank -->
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <h6 class="text-muted font-weight-bolder">
              Linkedin
            </h6>
            <h6 class="mb-0">
              <a href="https://www.linkedin.com/in/panos-paoulinis-872115101/" target="_blank">Link</a>
            </h6>
          </div>
          <div>
            <h6 class="text-muted font-weight-bolder">
              Facebook
            </h6>
            <h3 class="mb-0">
              -
            </h3>
          </div>
          <div>
            <h6 class="text-muted font-weight-bolder">
              Instagram
            </h6>
            <h3 class="mb-0">
              -
            </h3>
          </div>
        </div>
        <!--/ follower projects rank -->
      </b-card>
    </b-col>
  </b-row>
  
</template>

<script>
import { BRow, BCard, BAvatar, BBadge, BCol } from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BBadge,
    BAvatar,
  },
}
</script>
